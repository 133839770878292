export const APP_NAME = "TellusR";
export const TELLUSR_TOKEN = "TELLUSR_TOKEN";

export const FORCE_PROD = true; //Force use production endpoints, not develop.
export const AUTH_SERVICE_PROD = "https://signon.tellusr.com";
export const AUTH_SERVICE_DEV = "http://localhost:8181";

export const API_SERVICE_PROD = "https://agent.tellusr.com";
export const API_SERVICE_DEV = "http://localhost:8080";

export const secretKey = 'inY%Tf%a%.K5~,-l=,Ze+lZ!vi1k{G!O';

export const DEFAULT = "default";
export const MENUS = "menus";
export const ICON = "icon";
export const DISPLAY_TEXT = "display_text";


export const DefaultToastDelay = 30 * 1000;//30 seconds
export const ShortToastDelay = 3 * 1000;

//Toast Variations
export const TOAST_TYPES = Object.freeze({
    SUCCESS: "success",
    INFO: "secondary",
    ERROR: "danger",
    WARNING: "warning"
});
//