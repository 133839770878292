import {httpClient} from "../utility/http-client";
import {HttpStatusCode} from "axios";


export async function getItem(apiPath, id) {
    try {
        const response = await httpClient.get(`${apiPath}/${id}`);
        if (response.status === HttpStatusCode.Ok) {
            return [true, response.data];
        } else {
            const msg = `Unexpected status code for get! API: ${apiPath}, Id: ${id}, Status: ${response.status}`;
            console.warn(msg);
            return [false, msg];
        }
    } catch (err) {
        console.error(err);
        return [false, err];
    }
}

export async function getItemList(apiPath, offset = 0, limit = 10) {
    try {
        let params = {
            offset: offset,
            limit: limit
        };
        const response = await httpClient.get(`${apiPath}`,
            {
                params: params
            }
        );
        if (response.status === HttpStatusCode.Ok) {
            return [true, response.data];
        } else {
            const msg = `Unexpected status code for get list! API: ${apiPath}, Status: ${response.status}`;
            console.warn();
            return [false, msg];
        }
    } catch (err) {
        console.error(err);
        return [false, err];
    }
}

export async function getItemListByName(apiPath, name = "", offset = 0, limit = 10) {
    try {
        let params = {
            name: name,
            offset: offset,
            limit: limit
        };
        const response = await httpClient.get(`${apiPath}`,
            {
                params: params
            }
        );
        if (response.status === HttpStatusCode.Ok) {
            return [true, response.data];
        } else {
            const msg = `Unexpected status code for get list by name! API: ${apiPath}, Status: ${response.status}`;
            console.warn(msg);
            return [false, msg];
        }
    } catch (err) {
        console.error(err);
        return [false, err];
    }
}

export async function createItem(apiPath, data) {
    try {
        const response = await httpClient.post(apiPath, data);
        if (response.status === HttpStatusCode.Created) {
            return [true, response.data];
        } else {
            const message = `Unexpected status code for create! API: ${apiPath}, Status: ${response.status}`
            console.warn(message);
            return [false, message];
        }
    } catch (err) {
        console.error(err);
        return [false, err]
    }
}

export async function updateItem(apiPath, id, data) {
    try {
        const response = await httpClient.put(`${apiPath}/${id}`, data);
        if (response.status === HttpStatusCode.Ok) {
            return [true, response.data];
        } else {
            const msg = `Unexpected status code for update! API: ${apiPath}, Id: ${id}, Status: ${response.status}`;
            console.warn(msg);
            return [false, msg];
        }
    } catch (err) {
        console.error(err);
        return [false, err];
    }
}

export async function deleteItem(apiPath, id) {
    try {
        const response = await httpClient.delete(`${apiPath}/${id}`);
        if (response.status === HttpStatusCode.NoContent) {
            return [true, ""];
        } else {
            const msg = `Unexpected status code for delete! API: ${apiPath}, Id: ${id}, Status: ${response.status}`;
            console.warn(msg);
            return [false, msg];
        }
    } catch (err) {
        console.error(err);
        return [false, err];
    }
}