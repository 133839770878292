import {httpClient} from "../utility/http-client";
import {authService} from "../utility/util";
import {HttpStatusCode} from "axios";

export const fetchUsers = async (emailQuery, max = 5) => {
    try {
        let params = {max: max};
        if (emailQuery) {
            params['email'] = emailQuery;
        }
        const response = await httpClient.get(`${authService}/admin/realms/tellusr-chat/users`, {params: params});
        if (response.status === HttpStatusCode.Ok) {
            return [true, response.data];
        } else {
            const msg = `Unexpected status code while fetching users: ${response.status}`;
            console.warn(msg);
            return [false, msg];
        }
    } catch (err) {
        console.error(err);
        return [false, err];
    }
}