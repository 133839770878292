import React from 'react';
import "../../css/user-dropdown-menu.css";
import {useAuth} from "../../context/AuthProvider";
import {logout, userFromKc} from "../../utility/auth-util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BLUE_SOFT_COLOR, GRAY_COLOR} from "../../utility/colors";
import {faUserCircle, faUser, faEnvelope, faRightFromBracket} from "@fortawesome/free-solid-svg-icons";


const UserMenu = ({}) => {
    const {_, kc} = useAuth();
    const user = userFromKc(kc);
    return (
        <div className="user-dropdown-menu dropdown">
            <FontAwesomeIcon className="dropdown-toggle"
                             color={BLUE_SOFT_COLOR}
                             icon={faUserCircle}
                             size={'2xl'}
                             id="dropdownUserMenu"
                             data-bs-toggle="dropdown"
                             aria-expanded="false"/>
            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownUserMenu">
                {user.username &&
                    <a className="dropdown-item text">
                        <FontAwesomeIcon
                            className={"me-2"}
                            color={GRAY_COLOR}
                            icon={faUser}
                            size={'sm'}/>
                        {user.username}
                    </a>
                }
                {user.email &&
                    <a className="dropdown-item text">
                        <FontAwesomeIcon
                            className={"me-2"}
                            color={GRAY_COLOR}
                            icon={faEnvelope}
                            size={'sm'}/>
                        {user.email}
                    </a>
                }
                <div className="dropdown-divider"></div>
                <div
                    className="dropdown-item text"
                    onClick={() => logout(kc)}>
                    <FontAwesomeIcon
                        className={"me-2"}
                        color={GRAY_COLOR}
                        icon={faRightFromBracket}
                        size={'sm'}/>
                    Logout
                </div>
            </div>
        </div>
    );
}


export default UserMenu;