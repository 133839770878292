import React from "react";
import '../../css/sidebar.css'
import {useTabs} from "../../context/TabsProvider";
import {useMenus} from "../../context/MenusProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getDisplayTextForMenu, getIconForMenu, getMenusForTab} from "../../utility/menus";

const SidebarMenu = () => {
    const {activeTab} = useTabs();
    const {selectedMenus, setMenuForTab} = useMenus();
    const menus = getMenusForTab(activeTab);

    const activeMenuDesign = (menu) => {
        return selectedMenus[activeTab] === menu ? 'active' : ''
    }

    return (
        <div className="sidebar-menu border-bottom">
            {menus.map((menu, index) => (
                <div key={index}
                     className={`sidebar-menu-item border-top ${activeMenuDesign(menu)}`}
                     onClick={() => setMenuForTab(activeTab, menu)}>
                    <FontAwesomeIcon
                        className={"sidebar-menu-icon"}
                        icon={getIconForMenu(menu)}
                        size={'sm'}/>
                    <span>{getDisplayTextForMenu(menu)}</span>
                </div>
            ))
            }
        </div>
    );
}

export default SidebarMenu;