import {authService} from "./util";
import Cookies from "universal-cookie";
import {httpClient} from "./http-client";
import {TELLUSR_TOKEN} from "./constants";

export const authConfig = {
    url: authService,
    realm: 'tellusr-chat',
    clientId: 'tellusr-agent'
};
export const initOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: true,
    pkceMethod: 'S256'
}

const cookies = new Cookies();
export const onNewAuthToken = (token) => {
    console.debug("[called] onNewAuthToken");
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    cookies.set(TELLUSR_TOKEN, token, {path: '/', sameSite: "strict"});
};

export const clearAuthToken = () => {
    console.debug("[called] clearAuthToken");
    httpClient.defaults.headers.common['Authorization'] = null;
    cookies.remove(TELLUSR_TOKEN, {path: '/'});
};


export const login = (kc) => {
    kc.login();
}
export const logout = (kc) => {
    kc.logout();
}

export const userFromKc = (kc) => {
    let hasIdToken = (kc && kc.idToken);
    return {
        username: hasIdToken ? kc.idTokenParsed.preferred_username : "",
        email: hasIdToken ? kc.idTokenParsed.email : "",
        name: hasIdToken ? kc.idTokenParsed.name : ""
    };
}
