import {DEFAULT, MENUS, ICON, DISPLAY_TEXT} from "./constants";
import {faHouse, faInfo, faComments, faUsers} from "@fortawesome/free-solid-svg-icons";

//Tabs
export const TAB_DASHBOARD = "dashboard";

//Menus
export const MENU_CHAT_CLIENT = "chat-client";
export const MENU_TELLUSR = "tellusr";
export const MENU_USERS = "users";

//Tab->Menus mapping, including default
export const TAB_MENU_MAPPING = {
    [TAB_DASHBOARD]: {
        [DEFAULT]: MENU_CHAT_CLIENT,
        [MENUS]: [MENU_CHAT_CLIENT, MENU_TELLUSR, MENU_USERS]
    }
}

//Menu -> name, icon
export const MENU_ICON_MAPPING = {
    [MENU_TELLUSR]: {
        [ICON]: faHouse,
        [DISPLAY_TEXT]: "TellusR"
    },
    [MENU_CHAT_CLIENT]: {
        [ICON]: faComments,
        [DISPLAY_TEXT]: "Chat Clients"
    },
    [MENU_USERS]: {
        [ICON]: faUsers,
        [DISPLAY_TEXT]: "Users"
    },
    [DEFAULT]: {
        [ICON]: faInfo,
        [DISPLAY_TEXT]: ""
    }
}


export const getMenusForTab = (tab) => {
    return TAB_MENU_MAPPING?.[tab]?.[MENUS] || []
}

export const getIconForMenu = (menu) => {
    return MENU_ICON_MAPPING[menu]?.[ICON] || MENU_ICON_MAPPING[DEFAULT][ICON];
}

export const getDisplayTextForMenu = (menu) => {
    return MENU_ICON_MAPPING[menu]?.[DISPLAY_TEXT] || menu;
}

