import React, {useEffect, useState} from "react";
import {ListWidget, RowData} from "../list/ListComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faUserCheck, faUsersSlash, faBan} from "@fortawesome/free-solid-svg-icons";
import {GRAY_SOFT_COLOR, GREEN_COLOR} from "../../utility/colors";
import {formatedDateFromTS} from "../../utility/util";
import {fetchUsers} from "../../service/user-apis";


const UserRowComponent = ({user}) => {
    let formattedDate = ""
    if (user?.createdTimestamp) {
        formattedDate = formatedDateFromTS(user?.createdTimestamp)
    }
    let verifyIcon;
    if (user?.emailVerified === true) {
        verifyIcon = <FontAwesomeIcon size={"sm"}
                                      icon={faCircleCheck}
                                      color={GREEN_COLOR}/>;
    } else {
        verifyIcon = <FontAwesomeIcon size={"sm"}
                                      icon={faBan}
                                      color={GRAY_SOFT_COLOR}/>;
    }
    let userEnableIcon;
    if (user?.enabled === true) {
        userEnableIcon = <FontAwesomeIcon size={"sm"}
                                          icon={faUserCheck}
                                          color={GREEN_COLOR}/>;
    } else {
        userEnableIcon = <FontAwesomeIcon size={"sm"}
                                          icon={faUsersSlash}
                                          color={GRAY_SOFT_COLOR}/>;
    }
    return (
        <>
            <RowData>{user?.username || ""}</RowData>
            <RowData>{userEnableIcon}</RowData>
            <RowData>{user?.email || ""}</RowData>
            <RowData>{verifyIcon}</RowData>
            <RowData>{formattedDate}</RowData>
        </>
    );
}

const UserList = () => {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        fetchUsers(null, 10).then(([success, res]) => {
            setUsers(success ? res : []);
        })
    }, []);

    const onClickUserListItem = (user) => {
        console.log(user);
    }

    return (
        <ListWidget
            widgetTitle={"Plugin Users"}
            listHeaders={["Username", "Enable", "Email", "Email Verified", "Create Time"]}
            listItems={users}
            renderRowComponent={item => <UserRowComponent user={item}/>}
            displayCreate={false}
            onClickListItem={item => onClickUserListItem(item)}
        />
    );
};

export default UserList;