import {createItem, updateItem, deleteItem, getItem, getItemList, getItemListByName} from "./generic-apis";


const tellusrAPIPath = "/api/tellusr-instances";

export const createTellusr = async (tellusr) => {
    return await createItem(tellusrAPIPath, tellusr);
}

export const updateTellusr = async (tellusrId, tellusr) => {
    return await updateItem(tellusrAPIPath, tellusrId, tellusr);
}

export const deleteTellusr = async (tellusrId) => {
    return await deleteItem(tellusrAPIPath, tellusrId);
}
export const getTellusr = async (tellusrId) => {
    return await getItem(tellusrAPIPath, tellusrId);
}

export const getTellusrList = async (offset = 0, limit = 10) => {
    return await getItemList(tellusrAPIPath, offset, limit);
}
export const getTellusrListByName = async (name = "", offset = 0, limit = 10) => {
    return await getItemListByName(tellusrAPIPath + "/search-by-name", name, offset, limit);
}



