import React from 'react';
import "../css/home.css";
import Header from "./header/Header";
import WidgetHolder from "./widget/WidgetHolder";
import Sidebar from "./sidebar/Sidebar";
import {useAuth} from "../context/AuthProvider";
import OfflineWarning from "./common/OfflineWarning";


const Home = ({}) => {
    const {auth} = useAuth();
    return (
        <div className="container shadow">
            <div className="row tellusr-header-container">
                <Header/>
            </div>
            <div className={"row tellusr-content-container"}>
                {auth===false &&
                    <OfflineWarning/>
                }
                {auth===true &&
                    <>
                        <div className={"col-auto p-0"}>
                            <Sidebar/>
                        </div>
                        <div className={"col p-0 widget-container overflow-x-auto"}>
                            <WidgetHolder/>
                        </div>
                    </>
                }
            </div>
        </div>

    );
}
export default Home;