import React from "react";
import {login} from "../../utility/auth-util";
import UserMenu from "./UserMenu";
import {useAuth} from "../../context/AuthProvider";
import {TellusrLogo} from "../common/TellusrLogo";

const Header = ({}) => {
    const {auth, kc} = useAuth();
    return (
        <div className="d-flex flex-row bg-light p-2">
            <div className={"tellusr-header-logo"}>
                <TellusrLogo/>
            </div>
            <div className={"ms-4 tellusr-nav-item"}>
                Dashboard
            </div>
            {auth === false &&
                <button type="button" className="btn-login shadow-sm rounded-3 ms-auto"
                        onClick={() => login(kc)}>Connect to TellusR</button>
            }
            {auth === true &&
                <div className={"ms-auto mt-auto mb-auto"}>
                    <UserMenu/>
                </div>
            }
        </div>
    );
}

export default Header;