import React from "react";
import WidgetHeader from "../widget/WidgetHeader";


export const ListHeader = ({headers}) => {
    return (
        <thead>
        <tr>{headers.map((header, index) => (<th key={index} className={"list-header-item text-truncate"} scope="col">{header}</th>))}</tr>
        </thead>
    );
}


export const RowData = ({children}) => {
    return (
        <td className={"list-item text-break"}>{children}</td>
    );
}

export const ListView = ({
                             headers, items, renderRowComponent,
                             onClickListItem = (_) => {
                             }
                         }) => {
    return (
        <div className={"overflow-x-auto"}>
            <table className="table table-striped table-hover">
                <ListHeader
                    headers={headers}/>
                <tbody>
                {items.map((item, index) => (
                    <tr key={index} onClick={() => onClickListItem(item)}>{renderRowComponent(item)}</tr>))}
                </tbody>
            </table>
        </div>
    );
}

export const ListWidget = ({
                               widgetTitle, listHeaders, listItems, renderRowComponent, displayCreate = false,
                               onClickCreate = () => {
                               },
                               onClickListItem = (_) => {
                               },
                           }) => {
    return (
        <>
            <WidgetHeader
                title={widgetTitle}
                displayCreate={displayCreate}
                onClickCreate={onClickCreate}/>
            <ListView
                headers={listHeaders}
                items={listItems}
                renderRowComponent={item => renderRowComponent(item)}
                onClickListItem={item => onClickListItem(item)}
            />
        </>
    )
}