import React from 'react';
import {Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter, Button} from 'react-bootstrap';

const ModalComponent = ({
                            show, title, body, footer,
                            cancelBtn, cancelBtnText, cancelAction,
                            saveBtn, saveBtnText, saveAction,
                            deleteBtn, deleteBtnText, deleteAction,
                            editBtn, editBtnText, editAction,
                            hideFooter, busy, size, centered, backdrop
                        }) => {
    return (
        <Modal show={show} onHide={cancelAction} size={size} centered={centered} backdrop={backdrop}>
            <ModalHeader closeButton>
                <ModalTitle className={"model-title"}>
                    {title}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                {body}
            </ModalBody>
            {!hideFooter &&
                <ModalFooter>
                    {footer}
                    {cancelBtn &&
                        <Button size={'sm'} variant="secondary" onClick={cancelAction}
                                disabled={busy}>{cancelBtnText}</Button>
                    }
                    {editBtn &&
                        <Button size={'sm'} variant="secondary" onClick={editAction}
                                disabled={busy}>{editBtnText}</Button>
                    }
                    {deleteBtn &&
                        <Button size={'sm'} variant="danger" onClick={deleteAction}
                                disabled={busy}>{deleteBtnText}</Button>
                    }
                    {saveBtn &&
                        <Button size={'sm'} variant="success" onClick={saveAction}
                                disabled={busy}>{saveBtnText}</Button>
                    }

                </ModalFooter>
            }
        </Modal>
    );
};

export default ModalComponent;
