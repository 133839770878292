import React, {createContext, useContext, useState} from 'react';
import {Toast, ToastBody, ToastContainer, ToastHeader} from "react-bootstrap";
import {generateUniqueId, timeAgo} from "../utility/util";
import {DefaultToastDelay, ShortToastDelay, TOAST_TYPES} from "../utility/constants";


const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);


export const ToastProvider = ({children}) => {
    const [toasts, setToasts] = useState([]);

    const displaySuccess = (message, title = '', delay = DefaultToastDelay) => {
        displayToast(message, title, delay, TOAST_TYPES.SUCCESS);
    }
    const displayError = (message, title = '', delay = DefaultToastDelay) => {
        displayToast(message, title, delay, TOAST_TYPES.ERROR);
    }
    const displayWarning = (message, title = '', delay = DefaultToastDelay) => {
        displayToast(message, title, delay, TOAST_TYPES.WARNING);
    }
    const displayInfo = (message, title = '', delay = DefaultToastDelay) => {
        displayToast(message, title, delay, TOAST_TYPES.INFO);
    }
    const displayToast = (message, title = '', delay = DefaultToastDelay, type = TOAST_TYPES.SUCCESS) => {
        const newToast = {
            id: generateUniqueId(),
            message,
            title,
            delay,
            type,
            timestamp: new Date(),
            show: true
        };
        setToasts((prevToasts) => [...prevToasts, newToast]);

        // Automatically remove toast after its delay
        // setTimeout(() => {
        //     setToasts((toasts) => toasts.filter((t) => t.id !== newToast.id));
        // }, delay);
    };

    const closeToast = (id) => {
        setToasts((prevToasts) =>
            prevToasts.map(toast =>
                toast.id === id ? {...toast, show: false} : toast
            )
        );
    };

    return (
        <ToastContext.Provider value={{displayToast, displaySuccess, displayError, displayWarning, displayInfo}}>
            {children}
            <ToastContainer position="top-end" className="tellusr-toast-container p-3">
                {toasts.map((toast) => (
                    <Toast key={toast.id} bg={toast.type} delay={toast.delay} autohide
                           show={toast.show}
                           onClose={() => closeToast(toast.id)}>
                        <ToastHeader>
                            <strong className="me-auto">{toast.title}</strong>
                            <small className="text-muted">{timeAgo(toast.timestamp)}</small> {/* Show time ago */}
                        </ToastHeader>
                        <ToastBody>{toast.message}</ToastBody>
                    </Toast>
                ))}
            </ToastContainer>
        </ToastContext.Provider>
    );
};
