import React, {useEffect, useRef, useState} from "react";
import TellusrAutoSuggest from "../common/TellusrAutoSuggest";
import {fetchUsers} from "../../service/user-apis";
import {getTellusr, getTellusrListByName} from "../../service/tellusr-apis";
import Form from 'react-bootstrap/Form';
import TellusrInput from "../common/TellusrInput";
import {isEmptyArray, isEmptyObject, isEmptyOrBlankString} from "../../utility/util";
import {ShortToastDelay} from "../../utility/constants";
import {createChatClient, getChatClient, updateChatClient} from "../../service/chat-clients-apis";
import {useToast} from "../../context/ToastProvider";


const ChatClientForm = ({
                            chatClientId = '',
                            onCreateSuccessful = (_) => {
                            },
                            onUpdateSuccessful = (_) => {

                            },
                            onClickDelete = (_, __) => {

                            }
                        }) => {
    const {displaySuccess, displayError} = useToast();

    const [busy, setBusy] = useState(false);
    const [updating, setUpdating] = useState(false);

    const [userSuggestions, setUserSuggestions] = useState([]);
    const [tellusrSuggestions, setTellusrSuggestions] = useState([]);
    const [errors, setErrors] = useState({})

    const [preSelectedTellusr, setPreSelectedTellusr] = useState({});
    const [preSelectedUsers, setPreSelectedUsers] = useState([]);
    let chatClientOriginalName = useRef('');//For update operation, name from server

    const [selectedTellusr, setSelectedTellusr] = useState({});
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [name, setName] = useState('');
    const [project, setProject] = useState('');
    const [profile, setProfile] = useState('');

    useEffect(() => {
        if (!isEmptyOrBlankString(chatClientId)) {
            setBusy(true);
            getChatClient(chatClientId)
                .then(async ([success, res]) => {
                    if (success) {
                        setName(res.name || "");
                        setProject(res.project || "");
                        setProfile(res.profile || "");
                        chatClientOriginalName.current = res.name || "";
                        const [tellusrSuccess, tellusrResponse] = await getTellusr(res.tellusrInstanceId);
                        setPreSelectedUsers(res.users || [])
                        setPreSelectedTellusr(tellusrSuccess ? tellusrResponse : {})
                    } else {
                        displayError(`Couldn't get chat client details! Please refresh.`, "Chat client details");
                    }
                })
                .finally(() => {
                    setBusy(false);
                })
        }
    }, [chatClientId]);


    const handleTellusRSelect = (items) => {
        setSelectedTellusr(items.length > 0 ? items[0] : {});
    };

    const handleUsersSelect = (items) => {
        setSelectedUsers(items);
    };

    function validateInputs() {
        const errors = {}
        if (isEmptyObject(selectedTellusr)) {
            errors.tellusr = "You need select a TellusR instance."
        } else if (isEmptyArray(selectedUsers)) {
            errors.user = "You need select user."
        } else if (isEmptyOrBlankString(name)) {
            errors.name = "Provide chat client name."
        } else if (isEmptyOrBlankString(project)) {
            errors.project = "Provide TellusR project name"
        }
        return errors;
    }

    const chatClientFromInputs = () => {
        return {
            tellusrInstanceId: selectedTellusr.id,
            users: selectedUsers.map((user) => ({
                username: user.username,
                email: user.email || ""
            })),
            name: name,
            project: project,
            profile: profile || "",
        };
    }

    const onClickSaveChatClient = () => {
        const newErrors = validateInputs();
        setErrors(newErrors);
        if (!isEmptyObject(newErrors)) {
            return;
        }
        setBusy(true);
        const newChatClient = chatClientFromInputs();
        createChatClient(newChatClient)
            .then(([success, response]) => {
                if (success) {
                    displaySuccess(`Successfully created chat client ${response.name}.`, "Create chat client", ShortToastDelay);
                    onCreateSuccessful(response.chatClientId)
                } else {
                    displayError(`Failed to create chat client ${name}.`, "Create chat client", ShortToastDelay);
                }
            }).finally(() => {
                setBusy(false);
            }
        );
    }


    const onClickUpdateChatClient = () => {
        const newErrors = validateInputs();
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return;
        }
        setUpdating(true);
        const updatedChatClient = chatClientFromInputs();
        updateChatClient(chatClientId, updatedChatClient)
            .then(([success, response]) => {
                if (success) {
                    displaySuccess(`Successfully updated chat client ${response.name}.`, "Update chat client", ShortToastDelay);
                    onCreateSuccessful(response.id)
                } else {
                    displayError(`Failed to update chat client ${name}.`, "Update chat client", ShortToastDelay);
                }
            }).finally(() => {
                setUpdating(false);
            }
        );
    }

    const onChangeUserQuery = (query) => {
        fetchUsers(query, 5).then(([success, response]) => {
            setUserSuggestions(success ? response : [])
        })
    }

    const onChangeTellusrQuery = (name) => {
        getTellusrListByName(name, 0, 5).then(([success, response]) => {
            setTellusrSuggestions(success ? response : [])
        })
    }

    const isBusy = () => busy || updating;

    const isUpdateOperation = () => !isEmptyOrBlankString(chatClientId);

    const labelCol = "col-3";
    const inputCol = "col-9";
    const inputMargin = "tm-b";

    return (
        <div className={"d-flex flex-column align-items-center"}>
            <Form className={"col-10"}>
                <TellusrAutoSuggest
                    margin={inputMargin}
                    labelCol={labelCol}
                    inputCol={inputCol}
                    label={"Select Tellusr"}
                    placeholder={"Type tellusr name..."}
                    allowMultipleSelection={false}
                    itemSuggestions={tellusrSuggestions}
                    initiallySelectedItems={isEmptyObject(preSelectedTellusr) ? [] : [preSelectedTellusr]}
                    onChangeQuery={(name) => onChangeTellusrQuery(name)}
                    renderSuggestItem={(tellusr) => (<>{tellusr.name} <br/> {tellusr.url}</>)}
                    renderSelectedItem={(tellusr) => (<>{tellusr.name} <br/>{tellusr.url}</>)}
                    itemComparator={(tellusr) => tellusr.id}
                    error={errors.tellusr || ""}
                    onSelectedItems={(items) => handleTellusRSelect(items)}
                    disabled={isBusy()}
                />

                <TellusrAutoSuggest
                    margin={inputMargin}
                    labelCol={labelCol}
                    inputCol={inputCol}
                    label={"Select user"}
                    placeholder={"Type user email..."}
                    itemSuggestions={userSuggestions} a
                    initiallySelectedItems={preSelectedUsers}
                    onChangeQuery={(query) => onChangeUserQuery(query)}
                    renderSuggestItem={(user) => (<>{user.username} <br/> {user.email}</>)}
                    renderSelectedItem={(user) => (<>{user.username} <br/>{user.email}</>)}
                    itemComparator={(item) => item.username}
                    error={errors.user || ""}
                    onSelectedItems={(items) => handleUsersSelect(items)}
                    disabled={isBusy()}
                />
                <TellusrInput
                    margin={inputMargin}
                    labelCol={labelCol}
                    inputCol={inputCol}
                    name={"name"}
                    label={"Name"}
                    placeholder={"Chat client name"}
                    value={name}
                    onChangeValue={(value) => setName(value)}
                    error={errors.name || ""}
                    disabled={isBusy()}
                />
                <TellusrInput
                    margin={inputMargin}
                    labelCol={labelCol}
                    inputCol={inputCol}
                    name={"project"}
                    label={"Project"}
                    placeholder={"TellusR project name"}
                    value={project}
                    onChangeValue={(value) => setProject(value)}
                    error={errors.project || ""}
                    disabled={isBusy()}
                />
                <TellusrInput
                    margin={inputMargin}
                    labelCol={labelCol}
                    inputCol={inputCol}
                    name={"profile"}
                    label={"Profile"}
                    placeholder={"TellusR profile name"}
                    value={profile}
                    onChangeValue={(value) => setProfile(value)}
                    error={errors.profile || ""}
                    disabled={isBusy()}
                />
                <div className={"d-flex"}>
                    {isUpdateOperation() &&
                        <>
                            <button type={"button"} className={"btn btn-danger ms-auto"} disabled={isBusy()}
                                    onClick={() => onClickDelete(chatClientId, chatClientOriginalName.current)}>
                                <span>Delete</span>
                            </button>
                            <button type={"button"} className={"btn btn-success tm-l"} disabled={isBusy()}
                                    onClick={() => onClickUpdateChatClient()}>
                                {!updating && <span>Update</span>}
                                {updating &&
                                    <>
                                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                        <span className={"ms-1"} role="status">Updating...</span>
                                    </>
                                }
                            </button>
                        </>
                    }
                    {!isUpdateOperation() &&
                        <button type={"button"} className={"btn btn-success ms-auto"} disabled={busy}
                                onClick={() => onClickSaveChatClient()}>
                            {!busy && <span>Save</span>}
                            {busy &&
                                <>
                                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                    <span className={"ms-1"} role="status">Saving...</span>
                                </>
                            }
                        </button>
                    }
                </div>
            </Form>

        </div>
    );
}

export default ChatClientForm;