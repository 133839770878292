import React, {useEffect, useState} from "react";
import {deleteTellusr, getTellusrList} from "../../service/tellusr-apis";
import {ListWidget, RowData} from "../list/ListComponents";
import {useModal} from "../../context/ModalProvider";
import TellusRCreateForm from "./TellusRCreateForm";
import TellusRUpdateForm from "./TellusRUpdateForm";
import {ShortToastDelay} from "../../utility/constants";
import {useToast} from "../../context/ToastProvider";


const TellusRRowComponent = ({tellusr}) => {
    return (
        <>
            <RowData>{tellusr?.name || ""}</RowData>
            <RowData>{tellusr?.url || ""}</RowData>
            <RowData>{tellusr?.username || ""}</RowData>
        </>
    );
}

const MaxTellusR = 100;

const TellusRList = () => {
    const {showModal, closeModal, setBusyModal} = useModal();
    const {displaySuccess, displayError} = useToast();
    const [tellusrInstances, setTellusrInstances] = useState([]);

    function updateTellusRList() {
        getTellusrList(0, MaxTellusR).then(([success, response]) => {
            setTellusrInstances(success ? response : []);
        })
    }

    useEffect(() => {
        updateTellusRList();
    }, []);

    const onTellusRCreated = (tellusrId) => {
        closeModal();
        updateTellusRList();
        displayTellusRDetails(tellusrId);
    }

    const onTellusRUpdated = (tellusrId) => {
        updateTellusRList();
    }

    const onTellusRDeleted = () => {
        closeModal();
        updateTellusRList();
    }


    const onDeleteTellusR = (tellusrId, tellusrName) => {
        showModal({
            title: "Delete Tellusr",
            body: `Are you sure you want to delete ${tellusrName} ?`,
            deleteBtn: true,
            deleteAction: () => {
                setBusyModal(true);
                deleteTellusr(tellusrId).then(([success, res]) => {
                    if (success) {
                        displaySuccess(`TellusR ${tellusrName} deleted successfully.`, "Delete Tellusr", ShortToastDelay)
                        onTellusRDeleted();
                    } else {
                        displayError(`Couldn't delete TellusR ${tellusrName}.`, "Delete Tellusr", ShortToastDelay)
                        closeModal();
                    }
                }).finally(() => {
                    setBusyModal(false);
                })
            },
            cancelBtn: true,
        })
    }

    const onClickCreateTellusR = () => {
        showModal({
            title: 'Create new TellusR instance',
            body: <TellusRCreateForm onCreateSuccessful={(tellusrId) => onTellusRCreated(tellusrId)}/>,
            hideFooter: true,
            backdrop: 'static'
        });
    }


    const displayTellusRDetails = (tellusrId) => {
        showModal({
            title: 'Update TellusR instance',
            body: <TellusRUpdateForm tellusrId={tellusrId}
                                     onUpdateSuccessful={(id) => onTellusRUpdated(id)}
                                     onClickDelete={(id, name) => onDeleteTellusR(id, name)}/>,
            hideFooter: true,
            backdrop: 'static'
        });
    }

    return (
        <ListWidget
            widgetTitle={"TellusR Instances"}
            listHeaders={["Name", "URL", "Username"]}
            listItems={tellusrInstances}
            renderRowComponent={item => <TellusRRowComponent tellusr={item}/>}
            displayCreate={true}
            onClickCreate={onClickCreateTellusR}
            onClickListItem={item => displayTellusRDetails(item.id)}
        />
    );
};

export default TellusRList;