import React, {useEffect, useState} from "react";
import {ListWidget, RowData} from "../list/ListComponents";
import {deleteChatClient, getChatClientList} from "../../service/chat-clients-apis";
import {useModal} from "../../context/ModalProvider";
import {useToast} from "../../context/ToastProvider";
import ChatClientForm from "./ChatClientForm";
import {ShortToastDelay} from "../../utility/constants";


const ChatClientRowComponent = ({chatClient}) => {
    const users = chatClient?.users ?? [];
    const emails = users.map(user => {
            return (<>{user.email || user.username}<br/></>)
        }
    );
    return (
        <>
            <RowData>{chatClient?.name || ""}</RowData>
            <RowData>{chatClient?.tellusrName || ""}</RowData>
            <RowData>{chatClient?.project || ""}</RowData>
            <RowData>{chatClient?.profile || ""}</RowData>
            <RowData>{chatClient?.tellusrUrl || ""}</RowData>
            <RowData>{emails}</RowData>
        </>
    );
}

const MaxChatClients = 100;

const ChatClientList = () => {
    const {showModal, closeModal, setBusyModal} = useModal();
    const {displaySuccess, displayError} = useToast();

    const [chatClients, setChatClients] = useState([]);

    function updateChatClientList() {
        getChatClientList(0, MaxChatClients).then(([success, response]) => {
            setChatClients(success ? response : []);
        })
    }

    useEffect(() => {
        updateChatClientList();
    }, []);


    const onChatClientCreated = (chatClientId) => {
        closeModal();
        updateChatClientList();
        displayChatClientDetails(chatClientId);
    }

    const onChatClientUpdated = (chatClientId) => {
        updateChatClientList();
    }

    const onChatClientDeleted = (chatClientId) => {
        closeModal();
        updateChatClientList();
    }

    const onDeleteChatClient = (chatClientId, chatClientName) => {
        showModal({
            title: "Delete chat client",
            body: `Are you sure you want to delete ${chatClientName} ?`,
            deleteBtn: true,
            deleteAction: () => {
                setBusyModal(true);
                deleteChatClient(chatClientId).then(([success, res]) => {
                    if (success) {
                        displaySuccess(`Chat client ${chatClientName} deleted successfully.`, "Delete chat client", ShortToastDelay)
                        onChatClientDeleted();
                    } else {
                        displayError(`Couldn't delete chat client ${chatClientName}.`, "Delete chat client", ShortToastDelay)
                        closeModal();
                    }
                }).finally(() => {
                    setBusyModal(false);
                })
            },
            cancelBtn: true,
        })
    }

    const onClickCreateChatClient = () => {
        showModal({
            title: 'Create new chat client',
            body: <ChatClientForm onCreateSuccessful={(chatClientId) => onChatClientCreated(chatClientId)}/>,
            hideFooter: true,
            size: 'lg',
            backdrop: 'static'
        });
    }
    const displayChatClientDetails = (chatClientId) => {
        showModal({
            title: 'Update chat client',
            body: <ChatClientForm chatClientId={chatClientId}
                                  onUpdateSuccessful={(id) => onChatClientUpdated(id)}
                                  onClickDelete={(id, name) => onDeleteChatClient(id, name)}/>,
            hideFooter: true,
            size: 'lg',
            backdrop: 'static'
        });
    }


    return (
        <ListWidget
            widgetTitle={"Chat Clients"}
            listHeaders={["Name", "TellusR", "Project", "Profile", "URL", "User"]}
            listItems={chatClients}
            renderRowComponent={item => <ChatClientRowComponent chatClient={item}/>}
            displayCreate={true}
            onClickCreate={onClickCreateChatClient}
            onClickListItem={item => displayChatClientDetails(item.chatClientId)}
        />
    );
};

export default ChatClientList;