import {createItem, updateItem, deleteItem, getItem, getItemList, getItemListByName} from "./generic-apis";


const chatClientAPIPath = "/api/chat-clients";

export const createChatClient = async (chatClient) => {
    return await createItem(chatClientAPIPath, chatClient);
}

export const updateChatClient = async (chatClientId, chatClient) => {
    return await updateItem(chatClientAPIPath, chatClientId, chatClient);
}

export const deleteChatClient = async (chatClientId) => {
    return await deleteItem(chatClientAPIPath, chatClientId);
}
export const getChatClient = async (chatClientId) => {
    return await getItem(chatClientAPIPath, chatClientId);
}

export const getChatClientList = async (offset = 0, limit = 10) => {
    return await getItemList(chatClientAPIPath, offset, limit);
}
export const getChatClientListByName = async (name = "", offset = 0, limit = 10) => {
    return await getItemListByName(chatClientAPIPath + "/search-by-name", name, offset, limit);
}



