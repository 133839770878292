import React, {useEffect, useState} from 'react';
import {FormControl, FormGroup, FormLabel, ListGroup, ListGroupItem} from 'react-bootstrap';
import {isEmptyArray} from "../../utility/util";

const TellusrAutoSuggest = ({
                                itemSuggestions,
                                onChangeQuery,
                                renderSuggestItem,
                                renderSelectedItem,
                                allowMultipleSelection = true,
                                label = '',
                                placeholder = '',
                                type = "text",
                                margin = 'tsm-b',
                                labelCol = "col-3",
                                inputCol = "col-9",
                                error = '',
                                disabled = false,
                                itemComparator = (item) => item,
                                initiallySelectedItems = [],
                                onSelectedItems = (_) => {
                                }
                            }) => {
    const [query, setQuery] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        if (!isEmptyArray(initiallySelectedItems)) {
            setSelectedItems(initiallySelectedItems);
        }
    }, [initiallySelectedItems]);

    useEffect(() => {
        const handler = setTimeout(() => {
            onChangeQuery(query);
        }, 400);
        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    useEffect(() => {
        onSelectedItems(selectedItems);
    }, [selectedItems]);

    const handleInputFocus = () => {
        setShowSuggestions(true);
    };

    const handleInputBlur = () => {
        // Hide suggestions when the input loses focus, using a timeout to allow clicks
        setTimeout(() => setShowSuggestions(false), 200);
    };

    const _onItemSelect = (item) => {
        if (!allowMultipleSelection && selectedItems.length > 0) return;
        const isItemSelected = selectedItems.some(selectedItem => itemComparator(selectedItem) === itemComparator(item));
        if (!isItemSelected) {
            setSelectedItems([...selectedItems, item]);
        }
        setShowSuggestions(false);
        setQuery('');
    };

    const _onItemRemove = (item) => {
        setSelectedItems(selectedItems.filter((selectedItem) => itemComparator(selectedItem) !== itemComparator(item)));
    };


    return (
        <div className={`position-relative ${margin}`}>
            <FormGroup className={`row`}>
                <FormLabel className={`${labelCol} col-form-label`}>
                    {label}
                </FormLabel>
                <div className={`${inputCol} ps-0`}>
                    <FormControl
                        type={type}
                        placeholder={placeholder}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        disabled={disabled || (!allowMultipleSelection && selectedItems.length >= 1)}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                    />
                    {error &&
                        <div className={"tellusr-invalid-feedback"}>
                            {error}
                        </div>
                    }
                    {showSuggestions && itemSuggestions.length > 0 && (
                        <ListGroup className={`${inputCol} tellusr-suggest-wrapper position-absolute z-1`}>
                            {itemSuggestions.map((item, index) => (
                                <ListGroupItem
                                    className={"tellusr-suggested-item"}
                                    key={index}
                                    action
                                    onClick={() => _onItemSelect(item)}>
                                    {renderSuggestItem(item)}
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    )}
                </div>
            </FormGroup>


            {!isEmptyArray(selectedItems) && (
                <div className={"row mt-2"}>
                    <div className={`${labelCol}`}/>
                    <div className={`${inputCol} ps-0`}>
                        <ListGroup>
                            {selectedItems.map((item, index) => (
                                <ListGroupItem key={index}
                                               className="d-flex justify-content-between align-items-center tellusr-selected-item">
                                    {renderSelectedItem(item)}
                                    <button className="btn-close btn-sm" onClick={() => _onItemRemove(item)}/>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TellusrAutoSuggest;
