import React, {useState} from "react";
import {Formik, Form} from 'formik';
import TellusrFikInput from "../common/TellusrFikInput";
import {encryptPassword} from "../../utility/util";
import {createTellusr} from "../../service/tellusr-apis";
import _ from "lodash";
import * as Yup from 'yup';
import {useToast} from "../../context/ToastProvider";
import {ShortToastDelay} from "../../utility/constants";


const TellusRCreateForm = ({onCreateSuccessful}) => {
    const [busy, setBusy] = useState(false);
    const {displaySuccess, displayError} = useToast();
    const initialValues = {
        name: '',
        url: '',
        username: '',
        password: ''
    }

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        url: Yup.string().url("Invalid Url.").required('Url is required'),
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
    });

    const onSubmitTellusrForm = (tellusr) => {
        setBusy(true);
        tellusr.password = encryptPassword(tellusr.password)
        createTellusr(tellusr)
            .then(([success, response]) => {
                if (success) {
                    displaySuccess(`Successfully created TellusR ${response.name}.`, "Create TellusR", ShortToastDelay);
                    onCreateSuccessful(response.id)
                } else {
                    displayError(`Failed to create TellusR ${tellusr.name}.`, "Create TellusR", ShortToastDelay);
                }
            }).finally(() => {
                setBusy(false);
            }
        );
    }

    const labelCol = "col-3";
    const inputCol = "col-9";

    return (
        <div className={"d-flex flex-column"}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    onSubmitTellusrForm(_.cloneDeep(values));
                }}>
                <Form>
                    <TellusrFikInput
                        name={"name"}
                        label={"Name"}
                        placeholder={"TellusR instance name"}
                        type={"text"}
                        labelCol={labelCol}
                        inputCol={inputCol}/>
                    <TellusrFikInput
                        name={"url"}
                        label={"url"}
                        placeholder={"TellusR url"}
                        type={"text"}
                        labelCol={labelCol}
                        inputCol={inputCol}/>
                    <TellusrFikInput
                        name={"username"}
                        label={"Username"}
                        placeholder={"username"}
                        type={"text"}
                        labelCol={labelCol}
                        inputCol={inputCol}/>
                    <TellusrFikInput
                        name={"password"}
                        label={"Password"}
                        placeholder={"password"}
                        type={"password"}
                        labelCol={labelCol}
                        inputCol={inputCol}/>
                    <div className={"d-flex"}>
                        <button type={"submit"} className={"btn btn-success ms-auto"} disabled={busy}>
                            {!busy && <span>Save</span>}
                            {busy &&
                                <>
                                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                    <span className={"ms-1"} role="status">Saving...</span>
                                </>
                            }
                        </button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

export default TellusRCreateForm;