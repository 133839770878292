import React from "react";

export const TellusrLogo = ({}) => {
    return (
        <svg className="tellusr-logo" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1190.55 267.82">
            <defs>
                <style>
                    {`.cls-1{fill:#141b9b;stroke-width:0px;}`}
                </style>
            </defs>
            <path className="cls-1" d="m415.56,68.61h-66.02v-22.89h157.85v22.89h-66.28v163.44h-25.55V68.61Z"/>
            <path className="cls-1"
                  d="m495.15,164.44c0-38.86,29.81-69.48,67.35-69.48s64.68,30.88,64.68,70.28v7.19h-107.54c3.46,23.69,22.63,40.99,46.85,40.99,13.58,0,26.62-4.26,35.14-11.98l15.44,15.71c-15.44,11.71-31.68,17.3-51.64,17.3-39.4,0-70.28-30.35-70.28-70.01Zm107.81-10.65c-3.73-21.56-20.5-37.8-40.99-37.8-21.56,0-38.33,15.44-42.06,37.8h83.05Z"/>
            <path className="cls-1" d="m653,45.72l24.49-5.06v191.39h-24.49V45.72Z"/>
            <path className="cls-1" d="m709.42,45.72l24.49-5.06v191.39h-24.49V45.72Z"/>
            <path className="cls-1"
                  d="m765.32,182.01v-84.92h24.22v80.66c0,22.09,13.58,35.67,34.87,35.67,14.91,0,26.89-6.66,34.34-18.9v-97.43h24.49v134.96h-24.49v-14.37c-10.38,11.18-24.22,17.04-40.99,17.04-30.88,0-52.44-21.56-52.44-52.71Z"/>
            <path className="cls-1"
                  d="m902.14,214.48l13.84-15.97c13.31,11.18,28.48,17.3,43.92,17.3,19.7,0,33.54-8.78,33.54-21.56,0-10.38-7.19-16.24-22.63-18.37l-24.22-3.46c-26.89-3.73-40.2-16.24-40.2-37.53,0-23.69,21.3-40.2,52.17-40.2,19.7,0,37,5.59,53.77,17.3l-12.51,16.24c-14.91-9.85-28.48-14.37-42.86-14.37-17.04,0-28.48,7.99-28.48,19.7,0,10.65,6.66,15.97,23.16,18.37l24.22,3.19c26.62,3.99,40.46,16.5,40.46,37.53,0,23.96-23.96,42.06-55.9,42.06-22.89,0-43.66-7.19-58.3-20.23Z"/>
            <path className="cls-1"
                  d="m1040.82,45.72h83.05c36.74,0,61.76,22.09,61.76,54.57,0,24.49-15.17,43.12-38.86,50.58l41.53,81.19h-28.22l-38.86-77.73h-54.84v77.73h-25.55V45.72Zm81.19,87.31c22.63,0,37.8-12.78,37.8-32.47s-15.17-32.21-37.8-32.21h-55.63v64.68h55.63Z"/>
            <circle className="cls-1" cx="32.72" cy="133.9" r="30.45"/>
            <circle className="cls-1" cx="32.72" cy="235.59" r="30.45"/>
            <circle className="cls-1" cx="236.1" cy="235.59" r="30.45"/>
            <circle className="cls-1" cx="236.1" cy="133.9" r="30.45"/>
            <path className="cls-1"
                  d="m235.87,1.78c-5.3.04-10.47,1.52-15.06,4.06-.33.18-3.51,2.3-5.16,3.9-1.49,1.44-3.36,3.27-4.84,5.25,0,0-.01.01-.01.01-12.5,13.25-33.91,14.29-47.83,3.1-1.23-.99-6.96-7.64-9.09-9.37-10.2-8.27-25.9-9.46-36.79-1.6-3.01,2.21-5.65,4.84-7.85,7.85,0,.01-.01.01-.01.01-13.61,14.43-37.79,14.37-51.34-.17C40.63-9.26,2.7,2.43,2.49,32.22c-.4,24.13,28.02,38.93,47.66,25.08,0,0,3.83-3.18,6.22-5.77.73-.79,2.81-3.29,3.91-4.24.89-.77,1.83-1.54,2.39-1.95,13.97-10.16,35.65-8.75,46.7,4.26,1.25,1.69,6.54,6.84,7.82,7.79,14.44,13.62,14.37,37.8-.16,51.36-17.45,11.87-17.18,39.02.3,50.36,14.29,13.65,14.19,37.7-.3,51.21-24.16,17.07-12.34,55.25,17.38,55.38,24.15.4,38.91-28.03,25.09-47.67-.03-.01-4.99-5.67-7.85-7.85,0,0-.01-.01-.01-.01-14.37-13.56-14.36-37.58,0-51.17,13.56-8.65,17.37-29.17,7.87-42.54-2.47-3-4.67-5.55-7.85-7.85-.01,0-.01-.01-.01-.01-14.43-13.61-14.37-37.79.17-51.34,1.65-1.29,6.82-6.55,7.79-7.81,13.62-14.44,37.8-14.37,51.36.16,5.66,7.88,15.13,13.08,24.93,13.06,40.31-1.39,40.31-59.49,0-60.88Z"/>
        </svg>
    );
}
