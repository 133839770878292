import React from "react";
import '../../css/sidebar.css'
import SidebarUserInfo from "./SidebarUserInfo";
import SidebarMenu from "./SidebarMenu";
import SidebarFooter from "./SidebarFooter";

const Sidebar = ({}) => {
    return (
        <div className={"sidebar d-flex flex-column h-100"}>
            <SidebarUserInfo/>
            <SidebarMenu/>
            <SidebarFooter/>
        </div>
    );
}
export default Sidebar;