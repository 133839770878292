import {
    API_SERVICE_DEV,
    API_SERVICE_PROD,
    AUTH_SERVICE_DEV,
    AUTH_SERVICE_PROD, FORCE_PROD, secretKey
} from "./constants";
import _ from 'lodash';
import CryptoJS from 'crypto-js';

export function isDevelopmentMode() {
    if (FORCE_PROD) {
        return false;
    }
    return process.env.NODE_ENV === 'development';
}

export const apiService = isDevelopmentMode() ? API_SERVICE_DEV : API_SERVICE_PROD;
export const authService = isDevelopmentMode() ? AUTH_SERVICE_DEV : AUTH_SERVICE_PROD;


const defaultDateOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
};

export const formatedDate = (date) => {
    return new Intl.DateTimeFormat('en-GB', defaultDateOptions).format(date);
}
export const formatedDateFromTS = (timestamp) => {
    return formatedDate(new Date(timestamp))
}

export const isEmptyOrBlankString = (str) => _.isEmpty(_.trim(str));

export const isEmptyObject= (obj) => _.isEmpty(obj);
export const isEmptyArray= (arr) => _.isEmpty(arr);


//AES key (128-bit)
const toAesKey = (key) => {
    // Hash the key using SHA-256 and take the first 16 bytes (128 bits)
    const hash = CryptoJS.SHA256(key);
    return CryptoJS.lib.WordArray.create(hash.words.slice(0, 4)); // 16 bytes for AES-128
};

const encrypt = (plaintext, key) => {
    const secretKey = toAesKey(key); // Convert the key to an AES-compatible key
    const iv = CryptoJS.lib.WordArray.random(16); // Generate a random 16-byte IV

    const encrypted = CryptoJS.AES.encrypt(plaintext, secretKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    // Combine IV and ciphertext, then encode as Base64
    const ivAndCiphertext = iv.concat(encrypted.ciphertext);
    const encryptedBase64 = CryptoJS.enc.Base64.stringify(ivAndCiphertext);
    return encryptedBase64;
};


export const encryptPassword = (password) => {
    return encrypt(password, secretKey)
};


export const timeAgo = (date) => {
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);

    let interval = Math.floor(seconds / 60);
    if (interval > 1) return `${interval} minutes ago`;

    interval = Math.floor(seconds);
    if (interval > 1) return `${interval} seconds ago`;

    return "just now";
};

export const generateUniqueId = () => {
    return 'id-' + Date.now() + '-' + Math.random().toString(36).substring(2, 9);
}