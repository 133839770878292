import React from "react";
import '../../css/sidebar.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {useAuth} from "../../context/AuthProvider";
import {logout} from "../../utility/auth-util";

const SidebarFooter = ({}) => {
    const {auth, kc} = useAuth();
    return (
        <>
            {auth &&
                <div className={"sidebar-footer border-top mt-auto"}>
                    <div className={"sidebar-menu-item"} onClick={() => logout(kc)}>
                        <FontAwesomeIcon
                            className={"sidebar-menu-icon"}
                            icon={faRightFromBracket}
                            size={'sm'}/>
                        <span>Logout</span>
                    </div>
                </div>
            }
        </>
    );
}

export default SidebarFooter;
