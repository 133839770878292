import React, {useEffect, useState} from "react";
import * as Yup from 'yup';
import _ from "lodash";
import {Formik, Form} from 'formik';
import TellusrFikInput from "../common/TellusrFikInput";
import {encryptPassword} from "../../utility/util";
import {getTellusr, updateTellusr} from "../../service/tellusr-apis";
import {useToast} from "../../context/ToastProvider";
import {ShortToastDelay} from "../../utility/constants";


const TellusRUpdateForm = ({tellusrId, onUpdateSuccessful, onClickDelete}) => {
    const [updatePassword, setUpdatePassword] = useState(false);
    const [busy, setBusy] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [tellusr, setTellusr] = useState({
        id: '',
        name: '',
        url: '',
        username: '',
        password: ''
    })

    const {displaySuccess, displayError} = useToast();

    function getTellusrDetails() {
        setBusy(true);
        getTellusr(tellusrId)
            .then(([success, res]) => {
                if (success) {
                    setTellusr(prevTellusr => ({
                        ...prevTellusr,
                        id: res.id,
                        name: res.name,
                        url: res.url,
                        username: res.username
                    }));
                } else {
                    displayError(`Couldn't get TellusR details! Please refresh.`, "TellusR details");
                }
            })
            .finally(() => {
                setBusy(false)
            });
    }

    useEffect(() => {
        getTellusrDetails();
    }, []);

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        url: Yup.string().url("Invalid Url.").required('Url is required'),
        username: Yup.string().required('Username is required'),
        password: updatePassword ? Yup.string().required('Password is required') : Yup.string().notRequired(),
    });

    const onSubmitTellusrForm = (tellusr) => {
        setUpdating(true);
        tellusr['updatePassword'] = updatePassword;
        if (updatePassword) {
            tellusr.password = encryptPassword(tellusr.password)
        } else {
            delete tellusr.password;
        }
        updateTellusr(tellusrId, tellusr)
            .then(([success, response]) => {
                if (success) {
                    displaySuccess(`Successfully updated TellusR ${response.name}.`, "Update TellusR", ShortToastDelay);
                    onUpdateSuccessful(response.id)
                } else {
                    displayError(`Failed to update TellusR ${tellusr.name}.`, "Update TellusR", ShortToastDelay);
                }
            }).finally(() => {
                setUpdating(false);
            }
        );
    }

    const isBusy = () => busy || updating;


    return (
        <div className={"d-flex flex-column"}>
            <Formik
                initialValues={tellusr}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    onSubmitTellusrForm(_.cloneDeep(values));
                }}>
                <Form>

                    <TellusrFikInput
                        name={"name"}
                        label={"Name"}
                        placeholder={"TellusR instance name"}
                        type={"text"}/>

                    <TellusrFikInput
                        name={"url"}
                        label={"url"}
                        placeholder={"TellusR url"}
                        type={"text"}/>

                    <TellusrFikInput
                        name={"username"}
                        label={"Username"}
                        placeholder={"username"}
                        type={"text"}/>

                    {updatePassword &&
                        <TellusrFikInput
                            name={"password"}
                            label={"Password"}
                            placeholder={"password"}
                            type={"password"}/>
                    }
                    <div className={"row tsm-b"}>
                        <div className={"col-9 ms-auto form-check form-switch"}>
                            <label className="form-check-label" htmlFor="updatePassword"> Update password</label>
                            <input className="form-check-input" type="checkbox" role="switch" name="updatePassword"
                                   checked={updatePassword} onChange={(e) => {
                                setUpdatePassword(e.target.checked)
                            }} disabled={isBusy()}/>
                        </div>
                    </div>


                    <div className={"d-flex"}>
                        <button type={"button"} className={"btn btn-danger ms-auto"} disabled={isBusy()}
                                onClick={() => onClickDelete(tellusrId, tellusr.name)}>
                            <span>Delete</span>
                        </button>
                        <button type={"submit"} className={"btn btn-success tm-l"} disabled={isBusy()}>
                            {!updating && <span>Update</span>}
                            {updating &&
                                <>
                                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                    <span className={"ms-1"} role="status">Updating...</span>
                                </>
                            }
                        </button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

export default TellusRUpdateForm;