import React from "react";
import '../../css/sidebar.css'
import {useAuth} from "../../context/AuthProvider";
import {userFromKc} from "../../utility/auth-util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GRAY_COLOR, GREEN_COLOR} from "../../utility/colors";
import {faCircle, faEnvelope, faUser} from "@fortawesome/free-solid-svg-icons";

const SidebarUserInfo = () => {
    const {auth, kc} = useAuth();
    const user = userFromKc(kc);
    return (
        <div className={"sidebar d-flex flex-column"}>
            {auth &&
                <div className={"sidebar-user-info mt-3 mb-3 ms-auto me-auto"}>
                    {user.username &&
                        <a className="dropdown-item text text-truncate">
                            <FontAwesomeIcon
                                className={"sidebar-user-info-icon me-1"}
                                color={GRAY_COLOR}
                                icon={faUser}
                                size={'sm'}/>
                            <span>{user.username}</span>
                            <FontAwesomeIcon
                                className={"ms-2"}
                                color={GREEN_COLOR}
                                icon={faCircle}
                                size={'sm'}/>
                        </a>
                    }
                    {user.email &&
                        <a className="dropdown-item text text-truncate">
                            <FontAwesomeIcon
                                className={"sidebar-user-info-icon me-1"}
                                color={GRAY_COLOR}
                                icon={faEnvelope}
                                size={'sm'}/>
                            <span>{user.email}</span>
                        </a>
                    }
                </div>
            }
        </div>
    );
}
export default SidebarUserInfo;