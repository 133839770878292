import Keycloak from 'keycloak-js';
import React, {createContext, useState, useEffect, useContext} from 'react';
import {authConfig, clearAuthToken, initOptions, onNewAuthToken} from "../utility/auth-util";

const kc = new Keycloak(authConfig);
const AuthContext = createContext();

function updateToken() {
    console.debug(`[called] updateToken`)
    kc.updateToken(30).then((refreshed) => {
        console.debug(`[event-fired] updateToken->refreshed-> ${refreshed}`);
        if (refreshed) {
            onNewAuthToken(kc.token);
        }
    }).catch(() => {
        clearAuthToken();
    });
}

const monitorTokenExpire = () => {
    console.debug(`[monitor] token expire`);
    kc.onTokenExpired = () => {
        console.debug(`[event-fired] onTokenExpired`);
        updateToken();
    };
}

const monitorLogout = () => {
    console.debug(`[monitor] logout`);
    kc.onAuthLogout = () => {
        console.debug(`[event-fired] onAuthLogout`);
        clearAuthToken();
    };
}

export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        kc.init(initOptions)
            .then((authenticated) => {
                console.debug(`Authenticated: ${authenticated}`)
                setAuth(authenticated);
                if (authenticated) {
                    onNewAuthToken(kc.token);
                } else {
                    clearAuthToken();
                }
            }).catch(() => {
            clearAuthToken();
        });
        monitorTokenExpire();
        monitorLogout();
    }, []);

    return (
        <AuthContext.Provider value={{auth, kc}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
