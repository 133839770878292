import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BLUE_SOFT_COLOR} from "../../utility/colors";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const WidgetHeader = ({
                          title, displayCreate = false,
                          onClickCreate = () => {
                          }
                      }) => {
    return (
        <div className={"d-flex flex-row bg-white tsp border-bottom"}>
            <div className={"widget-header-title"}>{title}</div>
            {displayCreate &&
                <div className="create-new-wrapper rounded-circle" onClick={onClickCreate}>
                    <FontAwesomeIcon
                        color={BLUE_SOFT_COLOR}
                        icon={faPlus}
                        size={'lg'}/>
                </div>
            }
        </div>
    );
}

export default WidgetHeader;