import React, {createContext, useState, useContext} from 'react';
import ModalComponent from "../component/modal/ModalComponent";

const ModalContext = createContext();


const cancelDefault = false;
const cancelDefaultText = "Cancel";

const saveDefault = false;
const saveDefaultText = "Save";
const saveDefaultAction = () => {
};

const editDefault = false;
const editDefaultText = "Edit";
const editDefaultAction = () => {
};

const deleteDefault = false;
const deleteDefaultText = "Delete";
const deleteDefaultAction = () => {
};

const hideFooterDefault = false;

const modalSizeDefault = '';
const modalCenteredDefault = false;
const modalBackdropDefault = undefined;

export const ModalProvider = ({children}) => {
    const [modalBody, setModalBody] = useState(null);
    const [modalFooter, setModalFooter] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [show, setShow] = useState(false);
    const [_hideFooter, setHideFooter] = useState(hideFooterDefault);

    const [_cancelBtn, setCancelBtn] = useState(cancelDefault);
    const [_cancelBtnText, setCancelBtnText] = useState(cancelDefaultText);

    const [_saveBtn, setSaveBtn] = useState(saveDefault);
    const [_saveBtnText, setSaveBtnText] = useState(saveDefaultText);
    const [_saveAction, setSaveAction] = useState(saveDefaultAction);

    const [_deleteBtn, setDeleteBtn] = useState(deleteDefault);
    const [_deleteBtnText, setDeleteBtnText] = useState(deleteDefaultText);
    const [_deleteAction, setDeleteAction] = useState(deleteDefaultAction);

    const [_editBtn, setEditBtn] = useState(editDefault);
    const [_editBtnText, setEditBtnText] = useState(editDefaultText);
    const [_editAction, setEditAction] = useState(editDefaultAction);

    const [_busyModal, setBusyModal] = useState(false);

    const [_size, setSize] = useState(modalSizeDefault);
    const [_centered, setCentered] = useState(modalCenteredDefault);
    const [_backdrop, setBackdrop] = useState(modalBackdropDefault);

    const showModal = ({
                           title,
                           body,
                           footer = null,

                           cancelBtn = cancelDefault,
                           cancelBtnText = cancelDefaultText,

                           saveBtn = saveDefault,
                           saveBtnText = saveDefaultText,
                           saveAction = saveDefaultAction,

                           deleteBtn = deleteDefault,
                           deleteBtnText = deleteDefaultText,
                           deleteAction = deleteDefaultAction,

                           editBtn = editDefault,
                           editBtnText = editDefaultText,
                           editAction = editDefaultAction,
                           hideFooter = hideFooterDefault,
                           busyModal = false,
                           size = modalSizeDefault,
                           centered = modalCenteredDefault,
                           backdrop = modalBackdropDefault
                       }) => {
        setModalTitle(title);
        setModalBody(body);
        setModalFooter(footer);

        setCancelBtn(cancelBtn);
        setCancelBtnText(cancelBtnText);

        setSaveBtn(saveBtn);
        setSaveBtnText(saveBtnText);
        setSaveAction(() => saveAction);

        setDeleteBtn(deleteBtn);
        setDeleteBtnText(deleteBtnText);
        setDeleteAction(() => deleteAction);

        setEditBtn(editBtn);
        setEditBtnText(editBtnText);
        setEditAction(() => editAction);

        setHideFooter(hideFooter);
        setShow(true);
        setBusyModal(busyModal);
        setSize(size);
        setCentered(centered);
        setBackdrop(backdrop)
    };

    const closeModal = () => setShow(false);

    return (
        <ModalContext.Provider value={{showModal, closeModal, setBusyModal}}>
            {children}
            <ModalComponent show={show} title={modalTitle} body={modalBody} footer={modalFooter}
                            cancelBtn={_cancelBtn} cancelBtnText={_cancelBtnText} cancelAction={closeModal}
                            saveBtn={_saveBtn} saveBtnText={_saveBtnText} saveAction={_saveAction}
                            deleteBtn={_deleteBtn} deleteBtnText={_deleteBtnText} deleteAction={_deleteAction}
                            editBtn={_editBtn} editBtnText={_editBtnText} editAction={_editAction}
                            hideFooter={_hideFooter} busy={_busyModal} size={_size} centered={_centered}
                            backdrop={_backdrop}>
            </ModalComponent>
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);
