import {FormGroup, FormLabel} from "react-bootstrap";
import {ErrorMessage, Field} from "formik";
import React from "react";

const TellusrFikInput = ({
                          name, label,
                          placeholder = "",
                          type = "text",
                          margin = 'tsm-b',
                          labelCol = "col-3",
                          inputCol = "col-9",
                      }) => {
    return (
        <FormGroup className={`row ${margin}`}>
            <FormLabel htmlFor={name} className={`${labelCol} col-form-label`}>{label}</FormLabel>
            <div className={`${inputCol} ps-0`}>
                <Field
                    className={"form-control"}
                    type={type}
                    name={name}
                    placeholder={placeholder}/>
                <ErrorMessage className={"tellusr-invalid-feedback"} component={"div"} name={name}/>
            </div>
        </FormGroup>
    );
};

export default TellusrFikInput;