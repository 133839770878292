import React, {createContext, useContext, useState} from 'react';
import {TAB_DASHBOARD} from "../utility/menus";

// Create a context for the active tab
const TabsContext = createContext();

export const TabsProvider = ({children}) => {
    const [activeTab, setActiveTab] = useState(TAB_DASHBOARD); // Default tab

    return (
        <TabsContext.Provider value={{activeTab, setActiveTab}}>
            {children}
        </TabsContext.Provider>
    );
};

export const useTabs = () => useContext(TabsContext);
