// MenusContext.js
import React, {createContext, useState, useContext} from 'react';
import {DEFAULT} from "../utility/constants";
import {TAB_MENU_MAPPING} from "../utility/menus";

const MenusContext = createContext();

const generateTabDefaultMap = (mapping) => {
    const tabDefaultMap = {};
    for (const tab in mapping) {
        tabDefaultMap[tab] = mapping[tab][DEFAULT];
    }
    return tabDefaultMap;
};

export const MenusProvider = ({children}) => {
    //Default menus for each tab
    const defaultMenus = generateTabDefaultMap(TAB_MENU_MAPPING);

    const [selectedMenus, setSelectedMenus] = useState(defaultMenus);

    const setMenuForTab = (tab, menu) => {
        setSelectedMenus((prev) => ({
            ...prev,
            [tab]: menu,
        }));
    };

    return (
        <MenusContext.Provider value={{selectedMenus, setMenuForTab}}>
            {children}
        </MenusContext.Provider>
    );
};

export const useMenus = () => useContext(MenusContext);
