import {useTabs} from "../../context/TabsProvider";
import {useMenus} from "../../context/MenusProvider";
import React from "react";
import {MENU_CHAT_CLIENT, MENU_TELLUSR, MENU_USERS, TAB_DASHBOARD} from "../../utility/menus";
import TellusRList from "../tellusr/TellusRList";
import ChatClientList from "../chat-client/ChatClientList";
import UserList from "../user/UserList";

const componentsMap = {
    [TAB_DASHBOARD]: {
        [MENU_TELLUSR]: <TellusRList/>,
        [MENU_CHAT_CLIENT]: <ChatClientList/>,
        [MENU_USERS]: <UserList/>
    }
};

const WidgetHolder = () => {
    const {activeTab} = useTabs();
    const {selectedMenus} = useMenus();
    const widget = componentsMap?.[activeTab]?.[selectedMenus[activeTab]] || <></>;
    return (
      <div className={"tm"}>
          {widget}
      </div>
    );
}

export default WidgetHolder;