import {FormControl, FormGroup, FormLabel} from "react-bootstrap";
import React, {useState} from "react";

const TellusrInput = ({
                          name, label, value, onChangeValue,
                          placeholder = "",
                          type = "text",
                          margin = 'tsm-b',
                          labelCol = "col-3",
                          inputCol = "col-9",
                          required = false,
                          disabled = false,
                          error = ''
                      }) => {
    return (
        <FormGroup className={`row ${margin}`}>
            <FormLabel htmlFor={name} className={`${labelCol} col-form-label`}>{label}</FormLabel>
            <div className={`${inputCol} ps-0`}>
                <FormControl
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={event => onChangeValue(event.target.value)}
                    required={required}
                    disabled={disabled}
                />
                {error &&
                    <div className={"tellusr-invalid-feedback"}>
                        {error}
                    </div>
                }
            </div>
        </FormGroup>
    );
};

export default TellusrInput;