import {useAuth} from "../../context/AuthProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GRAY_SOFT_COLOR} from "../../utility/colors";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const OfflineWarning = ({}) => {
    const {auth} = useAuth();
    return (
        <>
            {!auth &&
                <div className="d-flex flex-row justify-content-center mt-3 mb-auto">
                    <div className="logged-out-msg border rounded-3 shadow-sm text">
                        You are not logged in your TellusR Account
                        <FontAwesomeIcon className="dropdown-toggle mt-auto mb-auto ms-2"
                                         color={GRAY_SOFT_COLOR}
                                         icon={faTriangleExclamation}
                                         size={'lg'}/>
                    </div>

                </div>
            }
        </>
    );
}

export default OfflineWarning;