import './App.css';
import AppProviders from "./context/AppProviders";
import Home from "./component/Home";

function App() {
    return (
        <AppProviders>
            <Home/>
        </AppProviders>
    );
}

export default App;
