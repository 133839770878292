import React from 'react';
import {TabsProvider} from "./TabsProvider";
import {MenusProvider} from "./MenusProvider";
import {ModalProvider} from "./ModalProvider";
import {ToastProvider} from "./ToastProvider";

const AppProviders = ({children}) => {
    return (
        <TabsProvider>
            <MenusProvider>
                <ToastProvider>
                    <ModalProvider>
                        {children}
                    </ModalProvider>
                </ToastProvider>
            </MenusProvider>
        </TabsProvider>
    );
};

export default AppProviders;